import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/heroSubpage'

const Careers = ({ data, location }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sub-page">
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.gatsbyImageData.src}
        />

        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          imageAltText={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
        />
      </div>

      <main>
        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
              <div className="mt-3 richtext divlink" dangerouslySetInnerHTML={{ __html: post.html }} />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
      </main>
    </Layout>
  )
}
export default Careers

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }     
    markdownRemark(frontmatter: { name: { eq: "careers" } }) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        alttext
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
      }
      html
    }
  }
`